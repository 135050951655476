<template>
  <div
    class="pvp overflow-auto h-100 w-100 mt-md-2 d-md-flex flex-md-column justify-content-md-between"
  >
    <base-header
      v-if="$device.isMobile"
      :title="$t('創建對局')"
      :is-title-bold="true"
      :back-on-click-left="false"
      :left-arrow="true"
      @on-click-left="exit"
    ></base-header>
    <div class="p-3 p-md-0">
      <b-container>
        <b-row class="">
          <row-item>
            <template v-slot:title>{{ $t('棋盤') }}</template>
            <template v-slot:content>
              <dropdown
                v-model="boardSize"
                :title="$t('棋盤')"
                :options="boardSizeOptions"
                :disabled="true"
              ></dropdown>
            </template>
          </row-item>
        </b-row>
        <b-row class="mt-3">
          <row-item>
            <template v-slot:title>{{ $t('時限') }}</template>
            <template v-slot:content>
              <dropdown
                v-model="time"
                :title="$t('時限')"
                :options="timeOptions"
              ></dropdown>
            </template>
          </row-item>
        </b-row>
        <b-row class="mt-3">
          <row-item>
            <template v-slot:title>{{ $t('讀秒') }}</template>
            <template v-slot:content>
              <dropdown
                v-model="byoYomiTime"
                :title="$t('讀秒秒數')"
                :options="byoYomiTimeOptions"
                class="flex-fill mr-2"
              ></dropdown>
              <dropdown
                v-model="byoYomiAmount"
                :title="$t('讀秒次數')"
                :options="byoYomiAmountOptions"
                class="flex-fill"
              ></dropdown>
            </template>
          </row-item>
        </b-row>
        <!-- <b-row class="mt-3">
          <row-item>
            <template v-slot:title>{{ $t('手合') }}</template>
            <template v-slot:content>
              <b-form-radio-group v-model="hasHandicap" class="w-100 d-flex">
                <b-form-radio
                  v-for="(handicapOption, index) in handicapOptions"
                  :key="handicapOption.value"
                  class="w-50 mr-0"
                  :class="{'mr-2': index < handicapOptions.length - 1}"
                  :value="handicapOption.value"
                  >{{ handicapOption.text }}</b-form-radio
                >
              </b-form-radio-group>
            </template>
          </row-item>
          <tip class="mt-3">{{ $t('段差一級讓一子，有讓子上限') }}</tip>
        </b-row> -->
        <b-row v-if="$device.isMobile" class="mt-3">
          <b-button
            class="w-100 font-weight-bold"
            variant="primary"
            size="fixed-lg"
            @click="createRoom"
            >{{ $t('建立') }}</b-button
          >
        </b-row>
      </b-container>
    </div>
    <b-button
      v-if="!$device.isMobile"
      class="nowrap font-weight-bold w-100"
      variant="primary"
      size="lg"
      @click="createRoom"
      >{{ $isJungo ? '対局開設' : $t('開始') }}</b-button
    >
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import RowItem from '@/components/Base/RowItem';
import Dropdown from '@/components/Base/Dropdown';
import errorMapping from '@/constant/error';
import socket from '@/lib/socket/socket.js';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    BaseHeader,
    RowItem,
    Dropdown,
  },
  data() {
    return {
      boardSizeOptions: [
        {value: 7, label: this.$t('{v1}路', {v1: 7})},
        {value: 9, label: this.$t('{v1}路', {v1: 9})},
      ],
      timeOptions: [
        {value: 0, label: this.$t('{v1}分鐘', {v1: 0})},
        {value: 5, label: this.$t('{v1}分鐘', {v1: 5})},
        {value: 10, label: this.$t('{v1}分鐘', {v1: 10})},
        {value: 15, label: this.$t('{v1}分鐘', {v1: 15})},
        {value: 20, label: this.$t('{v1}分鐘', {v1: 20})},
        {value: 25, label: this.$t('{v1}分鐘', {v1: 25})},
        {value: 30, label: this.$t('{v1}分鐘', {v1: 30})},
        {value: 40, label: this.$t('{v1}分鐘', {v1: 40})},
        {value: 50, label: this.$t('{v1}分鐘', {v1: 50})},
        {value: 60, label: this.$t('{v1}分鐘', {v1: 60})},
      ],
      byoYomiTimeOptions: [
        {value: 10, label: this.$t('{v1}秒', {v1: 10})},
        {value: 15, label: this.$t('{v1}秒', {v1: 15})},
        {value: 20, label: this.$t('{v1}秒', {v1: 20})},
        {value: 30, label: this.$t('{v1}秒', {v1: 30})},
        {value: 40, label: this.$t('{v1}秒', {v1: 40})},
        {value: 60, label: this.$t('{v1}秒', {v1: 60})},
      ],
      byoYomiAmountOptions: [
        {value: 1, label: this.$t('{v1}次', {v1: 1})},
        {value: 2, label: this.$t('{v1}次', {v1: 2})},
        {value: 3, label: this.$t('{v1}次', {v1: 3})},
        {value: 4, label: this.$t('{v1}次', {v1: 4})},
        {value: 5, label: this.$t('{v1}次', {v1: 5})},
      ],
      handicapOptions: [
        {value: false, text: this.$t('不讓子')},
        {value: true, text: this.$t('讓子')},
      ],
      time: 10,
      byoYomiTime: 10,
      byoYomiAmount: 3,
      boardSize: 7,
      hasHandicap: false,
      oldDropdown: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  watch: {
    boardSize() {
      this.$emit('board-size-change', this.boardSize);
      this.storageTimeSetting();
    },
    time() {
      this.storageTimeSetting();
    },
    byoYomiTime() {
      this.storageTimeSetting();
    },
    byoYomiAmount() {
      this.storageTimeSetting();
    },
    hasHandicap() {
      this.storageTimeSetting();
    },
  },
  created() {
    BackEvent.clearEvents();
    BackEvent.addEvent(() => {
      this.exit();
    });
    socket.onError(socket.SendEventEnum.PVP_CREATE_LOBBY, (result) => {
      const message = result.message;
      if (message === errorMapping.UNFINISHED_GAME) {
        this.$Message.error(this.$t('仍有進行中的對局'));
      }
      this.$store.commit('env/setIsLoading', false);
    });
    const prevTimeSettingString = localStorage.getItem('pvp.timeSetting');
    if (prevTimeSettingString) {
      const prevTimeSetting = JSON.parse(prevTimeSettingString);
      prevTimeSetting.time = prevTimeSetting.time / 60;
      prevTimeSetting.boardSize = 7;
      Object.assign(this.$data, prevTimeSetting);
    }
  },
  beforeDestroy() {
    socket.clearEvents([
      socket.SendEventEnum.PVP_CREATE_LOBBY,
      socket.ResponseEventEnum.PVP_CREATE_LOBBY_SUCCESSFULLY,
    ]);
  },
  destroyed() {
    this.$store.commit('env/setIsLoading', false);
  },
  methods: {
    exit() {
      BackEvent.popEvent();
      this.$router.push({name: this.$isJungo ? 'practice' : 'pvp-home'});
    },
    createRoom() {
      if (this.isLoading) {
        return;
      }

      this.$store.commit('env/setIsLoading', true);
      const data = {
        boardSize: this.boardSize,
        time: this.time * 60,
        byoYomiTime: this.byoYomiTime,
        byoYomiAmount: this.byoYomiAmount,
        isPublic: false,
        hasHandicap: this.hasHandicap,
        coursePlan: this.currentPlan.id,
      };
      this.$store.commit('pvp/setLobby', data);
      localStorage.setItem('pvp.timeSetting', JSON.stringify(data));
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_CREATE_LOBBY,
        data,
      });
      socket.once(
        socket.ResponseEventEnum.PVP_CREATE_LOBBY_SUCCESSFULLY,
        (result) => {
          const lobbyId = result?.data?.lobbyId;
          if (!lobbyId) {
            this.$Message.error(this.$t('錯誤'));
            return;
          }
          if (this.$device.isMobile) {
            this.$router.push({
              name: 'pvp-wait',
              params: {
                id: lobbyId,
              },
            });
          } else {
            this.$emit('is-pvp-wait', lobbyId);
          }
        }
      );
    },
    storageTimeSetting() {
      const data = {
        boardSize: this.boardSize,
        time: this.time * 60,
        byoYomiTime: this.byoYomiTime,
        byoYomiAmount: this.byoYomiAmount,
        isPublic: false,
        hasHandicap: this.hasHandicap,
      };
      localStorage.setItem('pvp.timeSetting', JSON.stringify(data));
    },
  },
};
</script>

<style lang="scss" scoped></style>
