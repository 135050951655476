<template>
  <div
    class="row-item d-flex justify-content-center align-items-center rounded-10 w-100 p-3 shadow-sm"
  >
    <div
      class="d-flex align-items-center mr-3"
      :style="`min-width: ${$isJungo ? '64px' : '32px'}`"
    >
      <slot name="title"></slot>
    </div>
    <div class="flex-fill d-flex justify-content-center align-items-center">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.row-item {
  height: 60px;
  background-color: $white;
  white-space: nowrap;
  color: $font-normal;
}
</style>
