<template>
  <div
    ref="dropdown"
    class="dropdown w-100 rounded-lg cursor-pointer text-14 d-flex align-items-center position-relative"
    :class="[$isJungo ? 'pl-3 pl-md-2' : 'pl-3', {disabled}]"
    style="padding-right: 11px"
    @click.stop="toggle"
  >
    <div class="flex-fill">
      {{ showLabel }}
    </div>
    <i v-if="!disabled" class="icon-Chevron-down text-16"></i>
    <action-sheet
      v-if="isOptionsShow && $device.isMobile"
      ref="action-sheet"
      :title="title"
      :sheet-height="optionsHeight"
      @close="isOptionsShow = false"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="position-relative option p-3 d-flex align-items-center"
        @click="select(option.value)"
      >
        {{ option.label }}
        <i
          v-if="value === option.value"
          class="icon-Check"
          :class="iconClass"
        ></i>
      </div>
    </action-sheet>
    <div
      v-if="isOptionsShow && !$device.isMobile"
      class="wrap-web-dropdown-mask"
      @click.stop="closeOptions"
    ></div>
    <div
      v-if="isOptionsShow && !$device.isMobile"
      class="wrap-web-dropdown w-100 bg-white"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="position-relative option d-flex align-items-center"
        @click.stop="select(option.value)"
      >
        {{ option.label }}
        <i
          v-if="value === option.value"
          class="icon-Check"
          :class="iconClass"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import ActionSheet from './ActionSheet.vue';
export default {
  components: {
    ActionSheet,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    iconClass: {
      type: String,
      default: 'text-positive',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOptionsShow: false,
    };
  },
  computed: {
    optionsHeight() {
      return 51 * (this.options.length + 1) + 'px';
    },
    showLabel() {
      const option = this.options.find((option) => option.value === this.value);
      return (option && option.label) || '';
    },
  },
  methods: {
    toggle() {
      this.isOptionsShow = !this.isOptionsShow;
    },
    select(value) {
      this.$emit('input', value);
      this.closeOptions();
    },
    closeOptions() {
      this.$device.isMobile
        ? this.$refs['action-sheet'].closeSheet()
        : (this.isOptionsShow = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: inline-block;
  height: 38px;
  border: 1px solid $line-1;

  .icon-Chevron-down {
    &:before {
      color: $primary;
    }
  }
}

.option {
  height: 51px;
  border-bottom: 1px solid $line-2;
  @media screen and (min-width: 768px) {
    height: 36px;
    border-bottom: none;
    padding: 16px;
    &:hover {
      background: $grayscale-5;
    }
  }
  .icon-Check {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 32px;
    @media screen and (min-width: 768px) {
      color: $primary;
      font-size: 24px;
    }
  }
}
.wrap-web-dropdown-mask {
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wrap-web-dropdown {
  top: 38px;
  left: 0;
  z-index: 10;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  position: absolute;
  min-width: 90px;
}
</style>
